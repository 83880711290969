<template>
  <atu-renderer :db-config="db" :module-id="moduleId" :on-init="initApp" :platform="platform" :on-destroy="destroyApp" :run-mode="runMode" v-if="source" :db-server-url="dbServerUrl" :startEvent="startEvent" :source="source" :functions="methodList" :storage="storage" :native-components="componentList" :debug="10" :plugins="plugins"/>
</template>

<script>

import {componentList} from "./componentList";
import db from "./db";
import {methodList} from "@/methodList";
import {SchemaKeeper} from "app-to-up-vue-renderer/src/renderer/utils/schemaKeeper";
//import {FirebaseAnalytics} from "./helpers/Analytics/FirebaseAnalytics";
//import firebaseConfig from "./../secrets/firebaseConfig";



export default {
  name: 'TestApp',
  computed: {
    methodList() {
      return methodList
    },
    db() {
      return db
    },
    componentList() {
      return componentList
    }
  },
  data: () => ({
    source: false,
    moduleId: false,
    storage: false,
    startEvent: "start",
    runMode: "debug",
    platform: "web",
    dbServerUrl: "",
    plugins: { /*AnalyticsProvider: FirebaseAnalytics.getInstance(firebaseConfig)*/  }
  }),
  async created() {

    // Get segments
    //const [, mId, mode] = window.location.pathname.split('/')

    

    // Store run mode
    this.runMode = process.env.VUE_APP_RUN_MODE

    // Set db url
    this.dbServerUrl = process.env.VUE_APP_DB_URL

    // Get appId from hash
    this.moduleId = parseInt(process.env.VUE_APP_MODULE_ID);

    // Start event
    this.startEvent = window.location.search?.split("?")?.[1] || "start"

    // Log start event
    console.log("Start event: ", this.startEvent)

    // Create schema keeper
    const schemaKeeper = new SchemaKeeper(this.app, this.moduleId, "web", this.runMode, require("@/assets/storage/schema.json"))

    // Get source
    this.source = await schemaKeeper.getSource()
  },

  /**
   * Before unmount
   * @return {Promise<void>}
   */
  async beforeUnmount() {
    
  },

  methods: {

    /**
     * Init app
     */
    async initApp(a2u) {

      // Get device
      const device = a2u.getDevice()

      // Log device
      console.log("Device: ", device)

    },

    /**
     * Destroy app
     * @param a2u
     */
    destroyApp() {

    }
  }
}
</script>
