import {createApp} from 'vue'
import App from './App.vue'
import AppToUpRenderer from "app-to-up-vue-renderer/src/install.js"
import AbApp from "ab-application/src/install"
import db from "./db";
import {getConfig} from "./config";
import "./styles/styles.css"

// Create app
const app = createApp(App);

// Config
app.config.unwrapInjectedRef = true

// Include app to up renderer
app.use(AppToUpRenderer)

// Init app app, backendUrl, socketUrl, routes, translations, stateConfig, dbConfig
app.use(AbApp, {
        //baseURL: process.env.VUE_APP_BACKEND_URL,
        //authURL: process.env.VUE_APP_DB_URL,
        requestTimeout: 10000,
        config: getConfig(`app-settings-${process.env.VUE_APP_MODULE_ID}`),
        db
    },
    false, () => {

        // Mount app
        app.mount('#app');
    })
